<template>
  <Header />
  <FilterByHome />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FilterByHome from "@/components/FilterByHome.vue";

export default {
  components: {
    Header,
    Footer,
    FilterByHome,
  },
};
</script>
