<template>
  <Header />
  <Herosection />
  <SaintOfTheDay />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Herosection from "@/components/HeroSectionPage.vue";
import Footer from "@/components/Footer.vue";
import SaintOfTheDay from "@/components/views/SaintOfTheDay.vue";

export default {
  components: {
    Header,
    Herosection,
    Footer,
    SaintOfTheDay,
  },
};
</script>
